import { useUILanguage } from 'hooks/useUILanguage';
import React from 'react';

function parseDate(dateString: string): Date {
  const dateObject = new Date(dateString);

  if (isNaN(dateObject.valueOf()))
    throw new Error(`Date string is invalid: ${dateString}`);

  return dateObject;
}

type Props = {
  /** Date in ISO 8601 format */
  date: string | undefined;
};

export const Time: React.FC<Props> = ({ date }) => {
  const language = useUILanguage();

  if (!date) return null;

  const formattedTime = parseDate(date).toLocaleTimeString(language, {
    timeStyle: 'short',
    timeZone: 'Europe/Helsinki',
  });

  return <time dateTime={date}>{formattedTime}</time>;
};
