import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import React from 'react';

type Props = {
  title: string | undefined;
};

export const InvisibleTitle: React.FunctionComponent<Props> = ({ title }) => (
  <VisuallyHidden.Root asChild>
    <h1>{title}</h1>
  </VisuallyHidden.Root>
);
