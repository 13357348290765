import { Card } from '@yleisradio/areena-types';
import ChevronRightIcon from 'assets/chevronRight.svg';
import DateIcon from 'assets/dateIcon.svg';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { GridContainer, GridElement } from 'components/Grid';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import React from 'react';
import { getCardKey } from 'utils/card';
import { getCanonicalGuidePath } from 'utils/url';
import { UpcomingProgram } from './UpcomingProgram';
import styles from './UpcomingPrograms.module.scss';

type Props = {
  cards: Card[];
};

export const UpcomingPrograms: React.FC<Props> = ({ cards }) => {
  const language = useUILanguage();
  const t = useTranslation();

  const isLoading = cards.length === 0;

  return (
    <GridContainer>
      <GridElement
        mobile={0}
        tabletLandscape={3}
        desktopSmall={6}
        desktop={8}
      />

      <GridElement
        mobile={12}
        tabletLandscape={9}
        desktopSmall={17}
        desktop={12}
      >
        <div className={classNames(styles.root, isLoading && styles.loading)}>
          <h3 className={styles.title}>{t('comingNext')}</h3>
          <div className={styles.programs}>
            {cards.map((card) => (
              <div key={getCardKey(card, '')} className={styles.program}>
                <UpcomingProgram card={card} />
              </div>
            ))}

            {isLoading && (
              <>
                <div className={styles.program} />
                <div className={styles.program} />
              </>
            )}

            <div className={styles.guideLink}>
              <Button
                text={t('radioGuide')}
                pointer={{ uri: getCanonicalGuidePath('radio', language) }}
                variant="text"
                iconLeft={DateIcon}
                iconRight={ChevronRightIcon}
              />
            </div>
          </div>
        </div>
      </GridElement>
    </GridContainer>
  );
};
