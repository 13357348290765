import { Control as ControlType } from '@yleisradio/areena-types';
import { Control as ControlComponent } from 'components/Controls/Control';
import React from 'react';
import { getControlKey } from 'utils/control';
import styles from './HeaderPlayControls.module.scss';

type Props = {
  playControl: ControlType;
  queueControl: ControlType | undefined;
};

export const HeaderPlayControls: React.FC<Props> = ({
  playControl,
  queueControl,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.playControl}>
        <ControlComponent
          control={playControl}
          controlKey={getControlKey(playControl)}
          as="button"
          size="sm"
          variant="primary"
          width="full"
        />
      </div>
      {queueControl ? (
        <div className={styles.queueControl}>
          <ControlComponent
            control={queueControl}
            controlKey={getControlKey(queueControl)}
            as="button"
            size="sm"
            variant="secondary"
          />
        </div>
      ) : null}
    </div>
  );
};
