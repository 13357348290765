import { Label as LabelType } from '@yleisradio/areena-types';
import React from 'react';
import classNames from 'classnames';
import styles from './LabelsByPriority.module.scss';
import { isRestrictionLabel } from 'components/Label/ContentRatingImage';
import { Label } from 'components/Label';
import { getLabelKey } from 'utils/card';

type Separator = 'bullet' | 'line' | 'lineBreak';
type Props = {
  labels: LabelType[];
  separator: Separator;
  priority: number;
  cardKey: string;
};

const getLabelClassName = (label: LabelType, separator: Separator) =>
  classNames(
    styles.label,
    separator === 'line' && styles.labelWithLineSeparator,
    separator === 'lineBreak' && styles.labelWithLineBreakSeparator,
    isRestrictionLabel(label) && styles.labelWithSpaceSeparator
  );

export const getLabelsByPriority = (
  labels: LabelType[],
  priority: number
): LabelType[] => labels.filter((label) => label.priority === priority);

export const LabelsByPriority: React.FunctionComponent<Props> = ({
  labels,
  separator,
  priority,
  cardKey,
}) => {
  const selectedLabels = getLabelsByPriority(labels, priority);

  if (!selectedLabels || !selectedLabels.length) return null;

  return (
    <div
      className={classNames(
        styles.container,
        separator === 'lineBreak' && styles.lineBreak
      )}
      data-testid="header-labels"
    >
      {selectedLabels.reduce<(React.ReactElement | string)[]>(
        (acc, label, index) => {
          const Element = separator === 'lineBreak' ? 'div' : 'span';
          acc.push(
            <Element
              key={getLabelKey(label, cardKey)}
              className={getLabelClassName(label, separator)}
            >
              <Label label={label} />
            </Element>,
            ' '
          );

          if (
            separator === 'bullet' &&
            !isRestrictionLabel(label) &&
            index < selectedLabels.length - 1
          )
            acc.push(
              <span key={index} className={styles.bullet}>
                {'·'}
              </span>,
              ' '
            );

          return acc;
        },
        []
      )}
    </div>
  );
};
