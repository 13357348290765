import { Menu, Navigator } from '@yleisradio/areena-types';
import { Container } from 'components/Container';
import { isNavigator } from 'components/Controls/Control/typeGuards';
import React from 'react';
import { getControlKey } from 'utils/control';
import { FooterLink } from './FooterLink';
import styles from './FooterLinks.module.scss';

function isFooterLinksMenu(menu: Menu): boolean {
  return menu.tag === 'footer-links';
}

function getNavigators(menu: Menu): Navigator[] {
  const controls = menu.groupings[0]?.controls || [];

  return controls.filter(isNavigator);
}

type Props = {
  menus: Menu[] | undefined;
};

export const FooterLinks: React.FC<Props> = ({ menus }) => {
  const menu = menus?.find(isFooterLinksMenu);

  if (!menu) return null;

  const controls = getNavigators(menu);

  return (
    <Container>
      <nav className={styles.root}>
        {controls.map((control) => (
          <React.Fragment key={getControlKey(control)}>
            <FooterLink control={control} />{' '}
          </React.Fragment>
        ))}
      </nav>
    </Container>
  );
};
