import { Content, HighlightCard } from '@yleisradio/areena-types';
import { ImagePosition } from 'components/HighlightCard';
import { isHighlightCard } from 'utils/content';

export function getImagePosition(
  highlightCard: HighlightCard,
  contentArray: Content[]
): ImagePosition {
  const indexInHighlightCards = contentArray
    .filter(isHighlightCard)
    .indexOf(highlightCard);

  if (indexInHighlightCards === -1)
    throw new Error('Given content object is not a highlight card');

  const isIndexOdd = indexInHighlightCards % 2 === 1;

  return isIndexOdd ? 'left' : 'right';
}
