import React from 'react';
import { Button } from 'components/Button';
import ChevronDown from 'assets/chevronDown.svg';
import ChevronUp from 'assets/chevronUp.svg';
import { useTranslation } from 'hooks/useTranslation';
import * as Collapsible from '@radix-ui/react-collapsible';

type Props = {
  children: React.ReactNode;
  isShowingMore: boolean;
  toggleShowMore: () => void;
};

export const ShowMore: React.FunctionComponent<Props> = ({
  children,
  isShowingMore,
  toggleShowMore,
}) => {
  const t = useTranslation();
  const showMoreButtonText = isShowingMore
    ? t('hideShowMoreInformation')
    : t('showMoreInformation');

  return (
    <Collapsible.Root onOpenChange={toggleShowMore} open={isShowingMore}>
      <Collapsible.Trigger asChild>
        <Button
          text={showMoreButtonText}
          variant="text"
          padding="vertical"
          size="xs"
          iconRight={isShowingMore ? ChevronUp : ChevronDown}
          dataTestId="show-more-button"
        />
      </Collapsible.Trigger>
      <Collapsible.Content data-testid="show-more-content">
        {children}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
