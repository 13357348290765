import { Card } from '@yleisradio/areena-types';
import { Dropdown, Option } from 'components/Dropdown';
import { useTranslation } from 'hooks/useTranslation';
import React from 'react';

type Props = {
  cards: Card[];
  selectedRegion: string | null;
  selectRegion: (channelId: string) => void;
};

export const RegionPicker: React.FC<Props> = ({
  cards,
  selectedRegion,
  selectRegion,
}) => {
  const t = useTranslation();
  const options = getOptions(cards);

  if (options.length === 0) return null;

  return (
    <Dropdown
      aria-label={t('selectRegionalChannel')}
      options={options}
      setValue={(value) => {
        selectRegion(value);
      }}
      value={selectedRegion ?? undefined}
    />
  );
};

function getOptions(cards: Card[]): Option[] {
  return cards.reduce((options: Option[], card) => {
    if (card.persistentState?.id && card.title) {
      options.push({ value: card.persistentState.id, label: card.title });
    }

    return options;
  }, []);
}
