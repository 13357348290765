import { Card as CardType, Control } from '@yleisradio/areena-types';
import classNames from 'classnames';
import { PlayButton } from 'components/Card/PlayButton';
import { Controls } from 'components/Controls';
import { GridContainer, GridElement } from 'components/Grid';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { useTranslation } from 'hooks/useTranslation';
import React, { useEffect, useRef, useState } from 'react';
import { getCardKey } from 'utils/card';
import { idFromPointer } from 'utils/pointer';
import { getFirstLineOfText, sanitizeHtmlId } from 'utils/ui-helpers';
import { LogoImage } from '../LogoImage';
import { GenericHeaderProps } from '../types';
import styles from './GenericHeader.module.scss';
import { HeaderImage } from './HeaderImage';
import { HeaderPlayControls } from './HeaderPlayControls';
import { LabelsByPriority, getLabelsByPriority } from './LabelsByPriority';
import { ShowMore } from './ShowMore';

function isTitleLarge(card: CardType | undefined): boolean {
  return (
    !!card && !card.overlayLogoImage && !card.controls?.some(isPreTitleControl)
  );
}

function isPreTitleControl(control: Control) {
  return ['back', 'series'].includes(control.tag ?? '');
}

export const GenericHeader: React.FunctionComponent<GenericHeaderProps> = ({
  viewKey,
  hasBackgroundImage,
  headerCard,
  headerImageAspectRatio,
  viewLogoImage,
  viewTitle,
}) => {
  const {
    image,
    controls = [],
    labels = [],
    overlayLogoImage = viewLogoImage,
  } = headerCard || {};
  const description = headerCard?.description?.trim();

  const t = useTranslation();
  const { areenaService } = useAreenaService();

  const [isShowingMore, setIsShowingMore] = useState(false);

  const descriptionElementRef = useRef<HTMLParagraphElement>(null);
  const isDescriptionOverflowing = useDescriptionOverflowState(
    descriptionElementRef,
    description
  );

  if (!hasBackgroundImage && !headerCard && !viewTitle) return null;

  const cardKey = headerCard ? getCardKey(headerCard, viewKey) : viewKey;
  const linksHeadingId = sanitizeHtmlId(`${cardKey}-links`);
  const programInfoHeadingId = sanitizeHtmlId(`${cardKey}-info`);

  const headerClassName = classNames(
    styles.header,
    hasBackgroundImage && styles.headerWithBackgroundImage,
    !!overlayLogoImage && styles.headerWithLogo,
    !!image && styles.headerWithImage
  );

  const preTitleControls = controls.filter(isPreTitleControl);

  const playControl = controls.find((c) => c.tag === 'play');
  const queueControl = controls.find((c) => c.tag === 'queue');

  const actionControls = controls.filter(
    (c) => !c.tag || c.tag === 'downloadLink'
  );
  const linkControls = controls.filter((c) => c.tag === 'link');

  const programInfoLabels = getLabelsByPriority(labels, 2);
  const showDebugPlayButton = headerCard?.presentation === 'playerCard';

  const descriptionElement = (
    <p
      className={classNames(
        styles.description,
        !isShowingMore && styles.descriptionMinimized
      )}
      dir="auto"
      ref={descriptionElementRef}
    >
      {isShowingMore ? description : getFirstLineOfText(description)}
    </p>
  );

  const linksElement =
    linkControls.length > 0 ? (
      <section aria-labelledby={linksHeadingId} className={styles.subSection}>
        <h2 className={styles.subtitle} id={linksHeadingId}>
          {t('related')}
        </h2>
        <Controls controls={linkControls} as="link" direction="vertical" />
      </section>
    ) : null;

  const programInfoElement =
    programInfoLabels.length > 0 ? (
      <section
        aria-labelledby={programInfoHeadingId}
        className={styles.subSection}
      >
        <h2 className={styles.subtitle} id={programInfoHeadingId}>
          {t('programInfo')}
        </h2>
        <LabelsByPriority
          cardKey={cardKey}
          priority={2}
          separator={'lineBreak'}
          labels={labels}
        />
      </section>
    ) : null;

  const hasShowMoreContent =
    !!linksElement || !!programInfoElement || isDescriptionOverflowing;

  const id = idFromPointer(headerCard?.pointer);

  const mockControlForLive: Control = {
    type: 'navigator',
    destination: {
      type: 'player',
      uri: `https://areena.api.yle.fi/v1/ui/players/${id}.json?language=fi&v=10&client=yle-areena-web`,
    },
  };

  const contentClassName = classNames(
    styles.headerContent,
    areenaService === 'radio' && styles.headerContentPodcast
  );

  const isSquareImage = headerImageAspectRatio === '1:1';

  return (
    <section className={headerClassName}>
      <GridContainer>
        {image ? (
          <>
            <GridElement // Component to add spacing between a square-image and left edge/element
              mobile={isSquareImage ? 3 : 0}
              tablet={0}
              desktopSmall={isSquareImage ? 2 : 0}
              orderDesktopSmall={1}
            />
            <GridElement
              mobile={isSquareImage ? 6 : 12}
              tablet={isSquareImage ? 6 : 10}
              tabletLandscape={isSquareImage ? 6 : 9}
              desktopSmall={isSquareImage ? 6 : 8}
              desktop={isSquareImage ? 8 : 12}
              orderDesktopSmall={2}
            >
              <div className={styles.image}>
                <HeaderImage
                  aspectRatio={headerImageAspectRatio}
                  image={image}
                />
              </div>
            </GridElement>
          </>
        ) : null}
        <GridElement mobile={12} tablet={10} desktopSmall={16} desktop={12}>
          <div className={contentClassName}>
            {preTitleControls.length > 0 ? (
              <div className={styles.preTitleControls}>
                <Controls controls={preTitleControls} as="button" />
              </div>
            ) : null}
            {overlayLogoImage ? (
              <div className={styles.logo}>
                <LogoImage logoImage={overlayLogoImage} />
              </div>
            ) : null}
            {showDebugPlayButton && <PlayButton control={mockControlForLive} />}
            {viewTitle && (
              <h1
                className={classNames(
                  styles.title,
                  isTitleLarge(headerCard) && styles.titleLarge
                )}
                dir="auto"
              >
                {viewTitle}
              </h1>
            )}
            {playControl ? (
              <div className={styles.playControls}>
                <HeaderPlayControls
                  playControl={playControl}
                  queueControl={queueControl}
                />
              </div>
            ) : null}
            <LabelsByPriority
              labels={labels}
              priority={0}
              separator="bullet"
              cardKey={cardKey}
            />

            {descriptionElement}

            <LabelsByPriority
              labels={labels}
              priority={1}
              separator="line"
              cardKey={cardKey}
            />

            {hasShowMoreContent && (
              <ShowMore
                isShowingMore={isShowingMore}
                toggleShowMore={() =>
                  setIsShowingMore((prevState) => !prevState)
                }
              >
                {linksElement}
                {programInfoElement}
              </ShowMore>
            )}

            <Controls
              controls={actionControls}
              variation="text"
              as="button"
              padding="vertical"
            />
          </div>
        </GridElement>
      </GridContainer>
    </section>
  );
};

function useDescriptionOverflowState(
  descriptionElementRef: React.RefObject<HTMLParagraphElement>,
  description: string | undefined
): boolean {
  const [isElementOverflowing, setElementOverflowState] = useState(false);
  const hasMoreThanOneLine = description !== getFirstLineOfText(description);

  useEffect(() => {
    const descriptionElement = descriptionElementRef.current;

    if (descriptionElement) {
      setElementOverflowState(
        descriptionElement.offsetHeight < descriptionElement.scrollHeight
      );
    }
  }, [descriptionElementRef]);

  return isElementOverflowing || hasMoreThanOneLine;
}
