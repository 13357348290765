import { Image as ImageType } from '@yleisradio/areena-types';
import classNames from 'classnames';
import { HeaderImageAspectRatio } from 'components/Header/types';
import NextImage from 'next/image';
import React from 'react';
import { defaultLoader, loaderUrl, squareImageLoader } from 'utils/cloudinary';
import styles from './HeaderImage.module.scss';

function getSizes(aspectRatio: HeaderImageAspectRatio): string {
  if (aspectRatio === '1:1') {
    return '(min-width: 1020px) 26vw, (min-width: 640px) 43vw, 45vw';
  }

  return '(min-width: 1020px) 44vw, (min-width: 640px) 75vw, 90vw';
}

interface HeaderImageProps {
  aspectRatio: HeaderImageAspectRatio;
  image: ImageType;
}

export const HeaderImage: React.FunctionComponent<HeaderImageProps> = ({
  aspectRatio,
  image,
}) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        aspectRatio === '1:1' && styles.wrapperSquare
      )}
    >
      <NextImage
        key={image.id}
        alt=""
        className={classNames(
          styles.image,
          aspectRatio === '1:1' && styles.imageSquare
        )}
        fill
        loader={aspectRatio === '1:1' ? squareImageLoader : defaultLoader}
        priority
        role="presentation"
        sizes={getSizes(aspectRatio) || undefined}
        src={loaderUrl(image)}
      />
    </div>
  );
};
