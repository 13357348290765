import { Image as ImageType, ViewPresentation } from '@yleisradio/areena-types';
import classNames from 'classnames';
import Image from 'next/image';
import React, { useEffect, useRef } from 'react';
import { loaderUrl, backgroundImageLoader } from 'utils/cloudinary';
import styles from './Background.module.scss';

interface Props {
  image: ImageType | undefined;
  isBlurred: boolean;
  viewPresentation: ViewPresentation | undefined;
}

const Background: React.FC<Props> = ({
  image,
  isBlurred,
  viewPresentation,
}) => {
  const isForPackageView = viewPresentation === 'package-view';

  const backgroundStartRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const backgroundStartElement = backgroundStartRef.current;
    const wrapperElement = wrapperRef.current;

    if (backgroundStartElement && wrapperElement && image) {
      const listener = () => {
        const contentStartOffset = backgroundStartElement.offsetTop;
        const contentScroll = window.scrollY - contentStartOffset;
        const scrollDistance = (window.innerHeight - contentStartOffset) / 2;

        const progress = Math.min(
          Math.max(contentScroll / scrollDistance, 0),
          1
        );
        wrapperElement.style.opacity = (1 - progress).toString();
      };

      listener();
      window.addEventListener('resize', listener);
      window.document.addEventListener('scroll', listener);

      return () => {
        window.removeEventListener('resize', listener);
        window.document.removeEventListener('scroll', listener);
      };
    }
  }, [image]);

  if (!isForPackageView && !image) {
    return null;
  }

  return (
    <>
      <div className="Background-start" ref={backgroundStartRef} />
      <div className={styles.stickyWrapper} ref={wrapperRef}>
        <div
          className={classNames(
            styles.background,
            isBlurred && styles.backgroundBlurred,
            isForPackageView && styles.backgroundInPackageView
          )}
        >
          {image && (
            <Image
              key={image.id}
              className={styles.image}
              src={loaderUrl(image)}
              fill
              alt=""
              role="presentation"
              priority
              // (16 / 9) / (375 / 320) ≈ 152%
              sizes="(min-width: 480px) 100vw, 152vw"
              loader={backgroundImageLoader({ aspectRatio: '16:9', isBlurred })}
            />
          )}
          <div className={styles.gradient1}></div>
          <div className={styles.gradient2}></div>
        </div>
      </div>
    </>
  );
};

export default Background;
