import classNames from 'classnames';
import React from 'react';
import { LogoImage } from '../LogoImage';
import { PackageHeaderProps } from '../types';
import styles from './PackageHeader.module.scss';

export const PackageHeader: React.FunctionComponent<PackageHeaderProps> = ({
  headerCard,
  viewLogoImage,
  viewTitle,
}) => {
  const logoImage = headerCard?.overlayLogoImage || viewLogoImage;

  return (
    <section className={classNames(styles.root, logoImage && styles.withLogo)}>
      <h1 className={styles.title} dir="auto">
        {viewTitle}
      </h1>
      {logoImage && <LogoImage logoImage={logoImage} forPackageHeader />}
    </section>
  );
};
