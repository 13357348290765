import dynamic from 'next/dynamic';
import { Control } from '@yleisradio/areena-types';
import React from 'react';

export interface WrapperProps {
  children: React.ReactNode;
  channelLinks: Control[] | undefined;
  isMessageToStudioDialogOpen: boolean;
  hideMessageToStudioDialog: () => void;
  messageToStudioDialogId: string | undefined;
}
export type WrapperComponent = React.FunctionComponent<WrapperProps>;

const MessageToStudioDialog = dynamic(
  () =>
    import(
      'components/Header/ChannelHeader/MessageToStudio/MessageToStudioDialog'
    ),
  {
    ssr: false,
  }
);

export const MessageToStudioDialogWrapper: WrapperComponent = ({
  children,
  channelLinks,
  isMessageToStudioDialogOpen,
  hideMessageToStudioDialog,
  messageToStudioDialogId,
}) => {
  return channelLinks && channelLinks.length > 0 ? (
    <span className="MessageToStudioWrapper">
      {children}
      <MessageToStudioDialog
        channelLinks={channelLinks}
        hide={hideMessageToStudioDialog}
        isVisible={isMessageToStudioDialogOpen}
        messageToStudioDialogId={messageToStudioDialogId}
      />
    </span>
  ) : null;
};
