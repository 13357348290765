import { Image } from '@yleisradio/areena-types';
import React from 'react';
import { channelPageLogoImageUrl } from 'utils/cloudinary';

function getSrcSet({
  image,
  width,
  height,
}: {
  image: Image;
  width: number;
  height: number;
}): string {
  return `${channelPageLogoImageUrl({
    image,
    width,
    height,
    dpr: 1,
  })} 1x, ${channelPageLogoImageUrl({
    image,
    width,
    height,
    dpr: 2,
  })} 2x`;
}

const tabletMediaQuery = 'screen and (min-width: 640px)';

type Props = {
  alt: string;
  image: Image;
};

export const ChannelLogo: React.FC<Props> = ({ alt, image }) => {
  return (
    <>
      <picture>
        <source
          media={tabletMediaQuery}
          width={219}
          height={53}
          srcSet={getSrcSet({ image, width: 219, height: 53 })}
        />

        <source
          width={161}
          height={39}
          srcSet={getSrcSet({ image, width: 161, height: 39 })}
        />

        <img
          alt={alt}
          src={channelPageLogoImageUrl({
            image,
            width: 219,
            height: 53,
            dpr: 1,
          })}
          width={219}
          height={53}
          style={{ display: 'block' }}
        />
      </picture>
    </>
  );
};
