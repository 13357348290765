import {
  Card,
  Control,
  Language,
  Notification,
  Player,
  Pointer,
  View,
} from '@yleisradio/areena-types';
import { useCards } from 'hooks/useCards';
import { useUILanguage } from 'hooks/useUILanguage';
import { useEffect } from 'react';
import { getChannelView } from 'services/areena-api/fetchers';
import useSWR from 'swr';
import { findLabel } from 'utils/card';
import { isList } from 'utils/content';
import { getRandomInt } from 'utils/random';

type FetcherParams = ['schedule', channelId: string, language: Language];

function fetcher([, channelId, language]: FetcherParams) {
  return getChannelView(channelId, language);
}

function getScheduleListPointer(view: View | undefined): Pointer | undefined {
  const scheduleList = view?.tabs?.[0]?.content?.[0];

  return isList(scheduleList) ? scheduleList.source : undefined;
}

export function useScheduleData(channelId: string): {
  channelLinks: Control[];
  channelPlayer: Player | undefined;
  currentProgram: Card | undefined;
  upcomingPrograms: Card[];
  errored: boolean;
  notifications: Notification[];
} {
  const language = useUILanguage();

  const { data: channelViewResponse, error: channelViewError } = useSWR(
    ['schedule', channelId, language] satisfies FetcherParams,
    fetcher
  );

  const viewNotifications = channelViewResponse?.notifications || [];

  const channelLinks = channelViewResponse?.data?.header?.controls || [];
  const channelPlayer = channelViewResponse?.data?.players?.live;

  const scheduleListPointer = getScheduleListPointer(channelViewResponse?.data);

  const {
    cards,
    errored: hasListFetchErrored,
    mutate: mutateSchedule,
    notifications: listNotifications = [],
  } = useCards({ source: scheduleListPointer, pageIndex: 0, pageSize: 3 });

  useEffect(() => {
    const currentProgramEndDate = findLabel(
      cards[0]?.labels,
      'broadcastEndDate'
    )?.raw;

    let refetchAfter = currentProgramEndDate
      ? Date.parse(currentProgramEndDate) -
        Date.now() +
        getRandomInt(10_000, 30_000)
      : 0;

    if (isNaN(refetchAfter) || refetchAfter <= 0) refetchAfter = 30_000;

    const refetchTimeout = setTimeout(() => {
      mutateSchedule();
    }, refetchAfter);

    return () => {
      clearTimeout(refetchTimeout);
    };
  }, [cards, mutateSchedule]);

  return {
    channelLinks,
    channelPlayer,
    currentProgram: cards[0],
    upcomingPrograms: cards.slice(1),
    errored: !!channelViewError || hasListFetchErrored,
    notifications: [...viewNotifications, ...listNotifications],
  };
}
