/**
 * Returns pseudorandom integer within specified range
 * @param min Lowest integer possible (inclusive)
 * @param max Highest integer possible (inclusive)
 * @returns Pseudorandom integer
 */

export function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
