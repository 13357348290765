import React from 'react';
import { GenericHeader } from './GenericHeader';
import { HeaderProps } from './types';
import { PackageHeader } from './PackageHeader';
import { ChannelHeader } from './ChannelHeader';
import { Card } from '@yleisradio/areena-types';

function isPlayerCard(card: Card): boolean {
  return card.presentation === 'playerCard';
}

function isChannelView(headerCard: Card): boolean {
  const subChannelCards = headerCard.cards || [];
  return isPlayerCard(headerCard) || subChannelCards.some(isPlayerCard);
}

export const Header: React.FunctionComponent<HeaderProps> = ({
  hasBackgroundImage,
  headerCard,
  headerImageAspectRatio,
  viewKey,
  viewLogoImage,
  viewPresentation,
  viewTitle,
}) => {
  if (isChannelView(headerCard)) {
    return (
      <ChannelHeader
        headerCard={headerCard}
        viewKey={viewKey}
        viewLogoImage={viewLogoImage}
        viewTitle={viewTitle}
      />
    );
  }

  switch (viewPresentation) {
    case 'package-view':
      return (
        <PackageHeader
          headerCard={headerCard}
          viewKey={viewKey}
          viewLogoImage={viewLogoImage}
          viewTitle={viewTitle}
        />
      );

    default:
      return (
        <GenericHeader
          hasBackgroundImage={hasBackgroundImage}
          headerCard={headerCard}
          headerImageAspectRatio={headerImageAspectRatio}
          viewKey={viewKey}
          viewLogoImage={viewLogoImage}
          viewTitle={viewTitle}
        />
      );
  }
};
