import { Card } from '@yleisradio/areena-types';
import { useRegionalChannelCookie } from 'hooks/useRegionalChannelCookie';
import { useRouter } from 'next/router';
import React from 'react';
import logger from 'services/logger';
import { isValidRegionalChannelId } from 'utils/channel';
import { idFromPointer } from 'utils/pointer';
import { prettyPath } from 'utils/url';
import { ChannelHeaderProps as Props } from '../types';
import styles from './ChannelHeader.module.scss';
import { ChannelLogo } from './ChannelLogo';
import { RegionPicker } from './RegionPicker';
import { Schedule } from './Schedule';

function getDefaultRegionalChannel(header: Card): string | null {
  const firstChannelId = header.cards?.[0]?.persistentState?.id;

  return firstChannelId || null;
}

function useRegionalChannelIdFromQuery(
  header: Card
): [channelId: string | null, setChannelId: (id: string) => void] {
  const router = useRouter();
  const parameter = '_c';
  const currentQuery = router.query[parameter];

  const setQueryParameter = (id: string) => {
    const updatedQuery = { ...router.query, [parameter]: id };
    void router.replace(
      {
        pathname: router.pathname,
        query: updatedQuery,
      },
      prettyPath(router.asPath, updatedQuery)
    );
  };

  if (typeof currentQuery === 'string') {
    if (isValidRegionalChannelId(currentQuery, header)) {
      return [currentQuery, setQueryParameter];
    } else {
      logger.warn('Ignoring invalid channel parameter (_c)');
    }
  } else if (Array.isArray(currentQuery)) {
    logger.warn('Ignoring multiple channel parameters (_c)');
  }
  return [null, setQueryParameter];
}

function useRegionalChannelId(
  header: Card
): [channelId: string | null, setChannelId: (id: string) => void] {
  const [selectedRegionalChannel, setCookie] = useRegionalChannelCookie(header);

  const [queryRegionalChannel, setQuery] =
    useRegionalChannelIdFromQuery(header);

  const defaultRegionalChannel = getDefaultRegionalChannel(header);

  const setSelectedRegionalChannel = (id: string) => {
    if (isValidRegionalChannelId(id, header)) {
      setCookie(id);
      setQuery(id);
    } else {
      throw new Error(`Invalid regional channel ID "${id}"`);
    }
  };

  return [
    queryRegionalChannel || selectedRegionalChannel || defaultRegionalChannel,
    setSelectedRegionalChannel,
  ];
}

// Here, "sole channel" is referred as any channel that has no sub-channels (regions)
function useSoleChannelId(header: Card): string | null {
  return idFromPointer(header.pointer);
}

export const ChannelHeader: React.FC<Props> = ({
  headerCard,
  viewLogoImage: logoImage,
  viewTitle: channelName,
}) => {
  const [regionalChannelId, selectRegionalChannel] =
    useRegionalChannelId(headerCard);
  const soleChannelId = useSoleChannelId(headerCard);

  const channelId = regionalChannelId || soleChannelId;

  if (!channelId || !channelName) return null;

  return (
    <section className={styles.root}>
      <div className={styles.channelNameAndRegion}>
        <h1 className={styles.channelName}>
          {logoImage ? (
            <ChannelLogo alt={channelName} image={logoImage} />
          ) : (
            channelName
          )}
        </h1>
        {headerCard.cards && (
          <div className={styles.regionPicker}>
            <RegionPicker
              cards={headerCard.cards}
              selectRegion={(id) => {
                selectRegionalChannel(id);
              }}
              selectedRegion={regionalChannelId}
            />
          </div>
        )}
      </div>

      <Schedule channelId={channelId} />
    </section>
  );
};
