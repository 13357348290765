import { CurrentSong as SongType, Language } from '@yleisradio/areena-types';
import { useUILanguage } from 'hooks/useUILanguage';
import React, { useEffect, useState } from 'react';
import { getCurrentSongForChannel } from 'services/areena-api/fetchers';
import useSWR from 'swr';
import { getParentChannel } from 'utils/channel';
import { getRandomInt } from 'utils/random';
import styles from './CurrentSong.module.scss';
import { Time } from 'components/Time';
import classNames from 'classnames';
import { useTranslation } from 'hooks/useTranslation';
import { DSNotification } from 'components/DSNotification';

type RequestKey = ['song', channelId: string, language: Language];

async function fetcher([
  ,
  channelId,
  language,
]: RequestKey): Promise<SongType | null> {
  return getCurrentSongForChannel(channelId, language);
}

type Props = {
  channelId: string;
  isParentLoading: boolean;
};

export const CurrentSong: React.FC<Props> = ({
  channelId,
  isParentLoading,
}) => {
  // Used to refetch song data at least once per minute when no song end time is known
  const [lastChecked, setLastChecked] = useState(Date.now());

  const language = useUILanguage();

  const t = useTranslation();

  const parentChannelId = getParentChannel(channelId);

  const {
    data: song,
    error: songError,
    isLoading: isSongLoading,
    mutate: mutateSong,
  } = useSWR(['song', channelId, language] satisfies RequestKey, fetcher);
  const {
    data: parentChannelSong,
    error: parentChannelSongError,
    isLoading: isParentChannelSongLoading,
    mutate: mutateParentChannelSong,
  } = useSWR(
    parentChannelId
      ? (['song', parentChannelId, language] satisfies RequestKey)
      : null,
    fetcher
  );

  const hasFetchErrored = !!songError || !!parentChannelSongError;
  const isLoading =
    isParentLoading || isSongLoading || isParentChannelSongLoading;
  const { performer, title, startTime, endTime } =
    song || parentChannelSong || {};

  useEffect(() => {
    // Don't retry if there was a server or network error
    if (hasFetchErrored) return;

    const refetch = () => {
      setLastChecked(Date.now());
      mutateSong();
      mutateParentChannelSong();
    };

    const timeUntilNextSongChange = endTime
      ? Date.parse(endTime) - Date.now()
      : 0;

    const refetchAfter =
      timeUntilNextSongChange > 0
        ? timeUntilNextSongChange + getRandomInt(3_000, 30_000) // Add random delay to spread the load on API
        : 60_000;

    const timeout = setTimeout(refetch, refetchAfter);

    return () => clearTimeout(timeout);
  }, [
    endTime,
    hasFetchErrored,
    lastChecked,
    mutateParentChannelSong,
    mutateSong,
  ]);

  if (hasFetchErrored)
    return (
      <DSNotification
        type="error"
        state="primary"
        title={t('genericError')}
        subtitle={t('songFetchError')}
      />
    );

  if (!title && !isLoading) return null;

  if (isLoading)
    return <div className={classNames(styles.root, styles.loading)} />;

  return (
    <div className={styles.root}>
      <span className={styles.nowPlaying}>{t('nowPlaying')}</span>
      <Time date={startTime} />
      {' '}
      {performer}
      {performer && ' - '}
      {title}
    </div>
  );
};
