import * as RadixTabs from '@radix-ui/react-tabs';
import { Tab as TabType } from '@yleisradio/areena-types';
import { useRouter } from 'next/router';
import React from 'react';
import { prettyPath } from 'utils/url';
import { TabContent } from './TabContent';
import styles from './Tabs.module.scss';

function getDefaultTabIndex(
  tabs: TabType[],
  selectedTabFromQuery: string | null
): number {
  const selectedTabFromQueryIndex = tabs.findIndex(
    (tab) => tab.slug === selectedTabFromQuery
  );

  if (selectedTabFromQueryIndex >= 0) return selectedTabFromQueryIndex;

  if (selectedTabFromQuery !== null) {
    const index = Number(selectedTabFromQuery);
    if (index in tabs) return index;
  }

  const defaultIndex = tabs.findIndex((t) => !!t.selected);

  if (defaultIndex >= 0) return defaultIndex;

  return 0;
}

interface TabsProps {
  tabs: TabType[];
}

export const Tabs: React.FunctionComponent<TabsProps> = ({ tabs }) => {
  const router = useRouter();
  const selectedTabFromQuery =
    typeof router.query.t === 'string' ? router.query.t : null;

  if (tabs.length === 0) {
    return null;
  }
  if (tabs.length === 1 && tabs[0] && !tabs[0].title) {
    return <TabContent tab={tabs[0]} />;
  }

  const handleOnTabSelect = (index: number) => {
    const newTabParameter = tabs.at(index)?.slug || index.toString();

    const updatedQuery = { ...router.query, t: newTabParameter };

    void router.replace(
      {
        pathname: router.pathname,
        query: updatedQuery,
      },
      prettyPath(router.asPath, updatedQuery),
      { scroll: false, shallow: true }
    );
  };

  return (
    <RadixTabs.Root
      onValueChange={(value) => handleOnTabSelect(Number(value))}
      value={getDefaultTabIndex(tabs, selectedTabFromQuery).toString()}
    >
      <div className={styles.tabListScroller}>
        <RadixTabs.List className={styles.tabList} data-testid="tab-list">
          {tabs.map((tab, i) => (
            <RadixTabs.Trigger
              key={i}
              className={styles.tab}
              value={i.toString()}
              data-testid="tab-trigger"
            >
              {tab.title}
            </RadixTabs.Trigger>
          ))}
        </RadixTabs.List>
      </div>

      {tabs.map((tab, i) => (
        <RadixTabs.Content key={i} value={i.toString()}>
          <TabContent tab={tab} />
        </RadixTabs.Content>
      ))}
    </RadixTabs.Root>
  );
};
