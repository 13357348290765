import { Notifications } from 'components/Notifications';
import { useTranslation } from 'hooks/useTranslation';
import React from 'react';
import { useScheduleData } from './useScheduleData';
import { CurrentProgram } from './CurrentProgram';
import styles from './Schedule.module.scss';
import { UpcomingPrograms } from './UpcomingPrograms';

type Props = {
  channelId: string;
};

export const Schedule: React.FC<Props> = ({ channelId }) => {
  const {
    channelPlayer,
    currentProgram,
    upcomingPrograms,
    errored,
    notifications,
    channelLinks,
  } = useScheduleData(channelId);
  const t = useTranslation();

  return (
    <div className={styles.root}>
      {errored && (
        <Notifications
          notifications={
            !currentProgram
              ? [{ uiMessage: t('channelErrorMessage') }, ...notifications]
              : notifications
          }
          notificationStyle="error"
        />
      )}
      <CurrentProgram
        card={currentProgram}
        channelId={channelId}
        channelPlayer={channelPlayer}
        channelLinks={channelLinks}
      />
      <UpcomingPrograms cards={upcomingPrograms} />
    </div>
  );
};
