import { Item } from '@yleisradio/areena-types';
import { useEffect } from 'react';
import { useTunnusContext } from 'contexts/TunnusContext';
import { updateFavoriteVisitedTime } from 'services/areena-api/fetchers';
import logger from 'services/logger';

type Props = {
  alternativeIds: string[] | undefined;
  item: Item | undefined;
};

export function useFavoriteUpdate({ alternativeIds, item }: Props): void {
  const { isAuthenticated } = useTunnusContext();

  useEffect(() => {
    if (!isAuthenticated) return;

    const itemIdsToUpdate: string[] = item ? [item.id] : [];
    if (alternativeIds) {
      itemIdsToUpdate.push(...alternativeIds);
    }

    for (const itemId of itemIdsToUpdate) {
      updateFavoriteVisitedTime(itemId).catch((e) => {
        logger.warn(e, "Error while updating favorite's visited time");
      });
    }
  }, [alternativeIds, isAuthenticated, item]);
}
