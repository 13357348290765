import { Control } from '@yleisradio/areena-types';
import { Controls } from 'components/Controls';
import React from 'react';
import styles from './HighlightControls.module.scss';

type Props = {
  controls: Control[];
};

export const HighlightControls: React.FunctionComponent<Props> = ({
  controls,
}) => {
  return (
    <>
      <div className={styles.mobile}>
        <Controls
          controls={controls}
          as="button"
          direction="horizontal"
          size="xs"
        />
      </div>
      <div className={styles.desktop}>
        <Controls
          controls={controls}
          as="button"
          direction="horizontal"
          size="sm"
        />
      </div>
    </>
  );
};
