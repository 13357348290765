import { Content, View as ViewType } from '@yleisradio/areena-types';
import { Container } from 'components/Container';
import { FooterLinks } from 'components/FooterLinks';
import { Header } from 'components/Header';
import { HeaderImageAspectRatio } from 'components/Header/types';
import { Tabs } from 'components/Tabs';
import { useAnalyticsForView } from 'hooks/analytics';
import React from 'react';
import { isList } from 'utils/content';
import Background from './Background';
import { InvisibleTitle } from './InvisibleTitle';
import styles from './View.module.scss';
import ViewMenus from './ViewMenus/ViewMenus';
import { useAutoplay } from 'components/Player/useAutoplay';

function isFirstContentHero(content: Content[] | undefined): boolean {
  const [firstContent] = content || [];

  return isList(firstContent) && firstContent.style?.size === 'extra-large';
}

function getMainContentClassName(
  view: ViewType,
  selectedTabSlug: string | null
): string | undefined {
  if (view.header || !view.tabs) return undefined;

  const selectedTab =
    (selectedTabSlug
      ? view.tabs.find((tab) => tab.slug === selectedTabSlug)
      : view.tabs.find((tab) => tab.selected)) || view.tabs[0];

  if (selectedTab && !isFirstContentHero(selectedTab.content))
    return styles.contentWithNoHeader;
}

interface ViewProps {
  headerImageAspectRatio: HeaderImageAspectRatio;
  view: ViewType;
  viewKey: string;
  selectedTab: string | null;
}

export const View: React.FunctionComponent<ViewProps> = ({
  headerImageAspectRatio,
  view,
  viewKey,
  selectedTab,
}) => {
  useAnalyticsForView(view.analytics);

  const isBackgroundBlurred = !!view.header?.image;

  const primaryPlayControl = view.header?.controls?.find(
    (c) => c.tag === 'play'
  );

  useAutoplay(primaryPlayControl);

  return (
    <div className="View" data-testid="view">
      <Background
        image={view.coverImage}
        isBlurred={isBackgroundBlurred}
        viewPresentation={view.presentation}
      />
      <div className={styles.containerWrapper}>
        <main
          className={getMainContentClassName(view, selectedTab)}
          id="maincontent"
        >
          {view.header ? (
            <Container>
              <Header
                viewKey={viewKey}
                viewLogoImage={view.overlayLogoImage}
                viewPresentation={view.presentation}
                viewTitle={view.title}
                hasBackgroundImage={!!view.coverImage}
                headerCard={view.header}
                headerImageAspectRatio={headerImageAspectRatio}
              />
            </Container>
          ) : (
            <InvisibleTitle title={view.title} />
          )}
          <Container>
            <ViewMenus menus={view.menus} />
          </Container>

          {view.tabs ? <Tabs tabs={view.tabs} /> : null}

          <FooterLinks menus={view.menus} />
        </main>
      </div>
    </div>
  );
};
