import { Navigator } from '@yleisradio/areena-types';
import { ControlLink } from 'components/DSLink';
import { useAnalyticsForControl } from 'hooks/analytics';
import React from 'react';
import styles from './FooterLink.module.scss';

type Props = {
  control: Navigator;
};

export const FooterLink: React.FC<Props> = ({ control }) => {
  const { trackAction } = useAnalyticsForControl(control.analytics);

  return (
    <>
      <span className={styles.mobile}>
        <ControlLink
          control={control}
          onClick={() => trackAction()}
          textSize="m"
        />
      </span>
      <span className={styles.desktop}>
        <ControlLink
          control={control}
          onClick={() => trackAction()}
          textSize="l"
        />
      </span>
    </>
  );
};
