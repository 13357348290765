import { Image as ImageType } from '@yleisradio/areena-types';
import Image from 'next/image';
import React from 'react';
import { loaderUrl, logoImageLoader } from 'utils/cloudinary';
import styles from './LogoImage.module.scss';
import classNames from 'classnames';

interface LogoImageProps {
  logoImage: ImageType | undefined;
  forPackageHeader?: boolean;
}

export const LogoImage: React.FunctionComponent<LogoImageProps> = ({
  logoImage,
  forPackageHeader,
}) => {
  if (!logoImage) return null;

  return (
    <Image
      key={logoImage.id}
      src={loaderUrl(logoImage)}
      alt=""
      role="presentation"
      loader={logoImageLoader}
      width={270}
      height={148}
      priority
      className={classNames(
        styles.image,
        forPackageHeader ? styles.forPackageHeader : styles.forGenericHeader
      )}
    />
  );
};
